import request from '../utils/request'
import { _GLOBAL } from '../api/server'
import secureStorage from '@/utils/secureStorage'

export function getSettingParamEofficeData () {
  // console.log('on api getSettingParamEofficeData')
  var data = null
  var uri = _GLOBAL.URLBACKEND + '/settingparams?Deskripsi=eofficeNeedUpdates'
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
  // console.log(response)
  return response
}

export function updateSettingParam(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/settingparams/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}
