<template>
  <transition name="menu">
    <form-users :is-edit="true"/>
  </transition>
</template>

<script>
import formUsers from './components/formUsers'

export default {
  name: 'EditFormUsers',
  components: { formUsers }
}
</script>

